import "./App.css"

import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from "react-router-dom"
import Display from "./components/Display"
import { useEffect } from "react"

function App() {
    useEffect(() => {
        var _gaq = _gaq || []
        _gaq.push(["_setAccount", "UA-23909909-1"])
        _gaq.push(["_trackPageview"])

        var ga = document.createElement("script")
        ga.type = "text/javascript"
        ga.async = true
        ga.src =
            ("https:" === document.location.protocol
                ? "https://ssl"
                : "http://www") + ".google-analytics.com/ga.js"
        var s = document.getElementsByTagName("script")[0]
        s.parentNode.insertBefore(ga, s)
    }, [])

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route
                        path="/:date([0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4})"
                        component={Display}
                    />
                    <Route
                        path="/:year/:month/:day"
                        component={withRouter(Display)}
                    />
                    <Route
                        path="/:year/:weekNumber"
                        component={withRouter(Display)}
                    />
                    <Route
                        path="/:weekNumber"
                        component={withRouter(Display)}
                    />
                    <Route path="/" component={withRouter(Display)} />
                </Switch>
            </Router>
        </div>
    )
}

export default App
