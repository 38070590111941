import React, { useState, useEffect } from "react"

import WeekDisplay from "./WeekDisplay"
import WeekSlide from "./WeekSlide"
import WeekSearch from "./WeekSearch"

import { NavLink } from "react-router-dom"

import svgToTinyDataUri from "../utils/minifySvgToDataUrl"

import styled from "styled-components/macro"

import {
    addDays,
    getDateOfISOWeek,
    getWeekNumber,
    getYMDObject
} from "../utils/dates"

const Display = (props) => {
    const params = props.match.params
    // console.log("params", params)

    var d = { type: null }
    if (
        undefined !== params.year &&
        undefined !== params.month &&
        undefined !== params.day
    ) {
        d.type = "date"
        d.year = parseInt(params.year)
        d.month = parseInt(params.month) - 1
        d.day = parseInt(params.day)
    } else if (undefined !== params.year && undefined !== params.weekNumber) {
        d.type = "year-week"
        d.year = parseInt(params.year)
        d.week = parseInt(params.weekNumber)
    } else if (undefined !== params.weekNumber) {
        d.type = "week"
        d.week = parseInt(params.weekNumber)
    } else if (undefined !== params.date) {
        const parts = params.date.split(".")
        d.type = "date"
        d.year = parseInt(parts[2])
        d.month = parseInt(parts[1]) - 1
        d.day = parseInt(parts[0])
    }

    let weekData
    if (d.type === "date") {
        weekData = getWeekNumber(new Date(d.year, d.month, d.day))
    } else if (d.type === "year-week") {
        const _d = getDateOfISOWeek(d.week, d.year)
        // console.log("*", _d)
        weekData = getWeekNumber(_d)
    } else if (d.type === "week") {
        const _d = getDateOfISOWeek(d.week, new Date().getFullYear())
        weekData = getWeekNumber(_d)
    } else {
        weekData = getWeekNumber(new Date())
    }

    const weekObject = {
        year: weekData[0],
        week: weekData[1],
        base: weekData[2]
    }

    const nowWeek = getWeekNumber(new Date())
    const nowDate = getYMDObject(new Date())
    const now = {
        year: nowDate.y,
        month: nowDate.m,
        date: nowDate.d,
        week: nowWeek[1]
    }

    const [currentWeek, setCurrentWeek] = useState(weekObject.week)
    const [currentYear, setCurrentYear] = useState(weekObject.year)
    const [baseDate, setBaseDate] = useState(weekObject.base)

    function setPath(y, w) {
        props.history.push("/" + y + "/" + w)
    }

    function onFieldsCompleted(y, m, d) {
        //console.log(y, m, d);
        const weekData = getWeekNumber(
            new Date(parseInt(y), parseInt(m) - 1, parseInt(d))
        )
        //console.log(weekData);
        setCurrentWeek(weekData[1])
        setCurrentYear(weekData[0])
        setBaseDate(weekData[2])

        const _desiredPath = "/" + y + "/" + m + "/" + d
        if (_desiredPath !== props.location.pathname) {
            props.history.push(_desiredPath)
        }
    }

    function handleWeekNumberChange(nav) {
        if (nav === 0) {
            setCurrentWeek(now.week)
            setCurrentYear(now.year)
            setPath(now.year, now.week)
        } else if (nav === -1) {
            if (currentWeek - 1 === 0) {
                const prev = addDays(baseDate, -7)
                const w = getWeekNumber(prev)

                setCurrentWeek(w[1])
                setCurrentYear(w[0])
                setBaseDate(prev)
                setPath(w[0], w[1])
            } else {
                const prev = addDays(baseDate, -7)

                setCurrentWeek(currentWeek - 1)
                setCurrentYear(prev.getFullYear())
                setBaseDate(prev)
                setPath(prev.getFullYear(), currentWeek - 1)
            }
        } else {
            // Test next weeks' weeknumber
            const next = addDays(baseDate, 7)
            const w = getWeekNumber(next)

            if (currentWeek + 1 === 53) {
                //console.log('Next: 53', w);
                // Confirm monday is in fact week 53
                if (w[1] === 53) {
                    setCurrentWeek(currentWeek + 1)
                    setBaseDate(next)
                    setPath(currentYear, currentWeek + 1)
                } else {
                    setCurrentWeek(1)
                    setCurrentYear(next.getFullYear())
                    setBaseDate(next)
                    setPath(next.getFullYear(), 1)
                }
            } else if (currentWeek + 1 === 54) {
                setCurrentWeek(1)
                setCurrentYear(next.getFullYear())
                setBaseDate(next)
                setPath(next.getFullYear(), 1)
            } else {
                //console.log('Next: *');
                setCurrentWeek(currentWeek + 1)
                setCurrentYear(next.getFullYear())
                setBaseDate(next)
                setPath(next.getFullYear(), currentWeek + 1)
            }
        }
    }

    useEffect(() => {
        const svgSource192 = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 192' width='192' height='192'><rect width='192' height='192' fill='#2e0e9f' stroke='#000' rx='7'></rect><text x='50%' y='56%' fill='#fff' dominant-baseline='middle' text-anchor='middle' font-size='144' font-family='sans-serif'>${currentWeek}</text></svg>`
        const svgSource256 = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' width='256' height='256'><rect width='256' height='256' fill='#2e0e9f' stroke='#000' rx='7'></rect><text x='50%' y='56%' fill='#fff' dominant-baseline='middle' text-anchor='middle' font-size='192' font-family='sans-serif'>${currentWeek}</text></svg>`
        const svgSource512 = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'><rect width='512' height='512' fill='#2e0e9f' stroke='#000' rx='7'></rect><text x='50%' y='56%' fill='#fff' dominant-baseline='middle' text-anchor='middle' font-size='384' font-family='sans-serif'>${currentWeek}</text></svg>`
        const svgSourceMinified192 = svgToTinyDataUri(svgSource192)
        const svgSourceMinified256 = svgToTinyDataUri(svgSource256)
        const svgSourceMinified512 = svgToTinyDataUri(svgSource512)

        var links = document.getElementsByTagName("link")
        for (let i = 0; i < links.length; i++) {
            if (
                links[i].rel === "icon" ||
                links[i].rel === "apple-touch-icon" ||
                links[i].rel === "shortcut icon" ||
                links[i].rel === "icon"
            ) {
                links[i].href = svgSourceMinified256
            }
        }

        const manifest = {
            short_name: "Ukenummer",
            name: "Ukenummer",
            description: "Hvilken uke er det?",
            display: "standalone",
            theme_color: "#2e0e9f",
            background_color: "#ffffff",
            icons: [
                {
                    src: svgSourceMinified192,
                    type: "image/svg+xml",
                    sizes: "192x192"
                },
                {
                    src: svgSourceMinified512,
                    type: "image/svg+xml",
                    sizes: "512x512"
                }
            ]
        }

        const stringManifest = JSON.stringify(manifest)
        const blob = new Blob([stringManifest], { type: "application/json" })
        const manifestURL = URL.createObjectURL(blob)
        document
            .querySelector("#manifest-placeholder")
            .setAttribute("href", manifestURL)
    }, [currentWeek])

    return (
        <div>
            <WeekDisplay
                onWeekNumberChange={handleWeekNumberChange}
                weekNumber={currentWeek}
                yearNumber={currentYear}
                shouldShowBackButton={currentWeek !== now.week}
            />
            <WeekSlide
                onWeekNumberChange={handleWeekNumberChange}
                weekNumber={currentWeek}
                yearNumber={currentYear}
            />
            {/*<WeekSearch onFieldsCompleted={onFieldsCompleted} />*/}
            <Footer>
                <Legend>
                    <NavLink to={`/${now.week}`}>
                        ukenummer.no/<span>{now.week}</span>
                    </NavLink>
                    <NavLink
                        to={{
                            pathname: `/${now.date}.${now.month}.${now.year}`
                        }}
                    >
                        ukenummer.no/
                        <span>
                            {now.date}.{now.month}.{now.year}
                        </span>
                    </NavLink>
                    <NavLink to={`/${now.year}/${now.month}/${now.date}`}>
                        ukenummer.no/
                        <span>
                            {now.year}/{now.month}/{now.date}
                        </span>
                    </NavLink>
                    {/*<a href={`/json`}>ukenummer.no/json</a>*/}
                </Legend>
                <FB
                    id="like"
                    style={{
                        textAlign: "right"
                    }}
                >
                    <iframe
                        title="Facebook"
                        src="https://www.facebook.com/plugins/like.php?app_id=233014146714484&amp;href=http://ukenummer.no&amp;send=false&amp;layout=box_count&amp;width=55&amp;show_faces=false&amp;action=like&amp;colorscheme=light&amp;font=trebuchet+ms&amp;height=90"
                        scrolling="no"
                        frameBorder="0"
                        style={{
                            border: "none",
                            overflow: "hidden",
                            width: 65,
                            height: 40
                        }}
                        allowtransparency="true"
                    ></iframe>
                </FB>
            </Footer>
            <Footer>
                <Legend>
                    <a href="mailto:ukenummer@losgordos.no">Get in touch</a>
                </Legend>
            </Footer>
        </div>
    )
}

const Footer = styled.div`
    display: flex;
    flex: 1;
    padding: 20px;
    align-items: center;
`

const Legend = styled.div`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    font-size: 13px;
    span {
        letter-spacing: 0.5px;
        padding-left: 1px;
    }
`

const FB = styled.div`
    display: flex;
    flex: 0 0 auto;
`

export default Display
