import React, { useState } from "react"

import styled from "styled-components/macro"

const WeekSearch = (props) => {
    const [state, setState] = useState({
        d: "",
        m: "",
        y: "",
        formFocused: false
    })

    function setDate() {
        props.onFieldsCompleted(state.y, state.m, state.d)
        if (document.activeElement) {
            document.activeElement.blur()
        }
    }

    function focusForm(id) {
        setState({
            formFocused: true,
            d: "",
            m: "",
            y: ""
        })
        if (undefined === id) {
            document.getElementById("day").focus()
        } else {
            document.getElementById(id).focus()
        }
    }

    function handleChange(event) {
        var change = {}
        change[event.target.name] = event.target.value
        this.setState(change, function () {
            if (state.d.length > 1) {
                document.getElementById("month").focus()
            }
            if (state.d.length > 1 && state.m.length > 1) {
                document.getElementById("year").focus()
            }
            if (
                state.d.length > 0 &&
                state.m.length > 0 &&
                state.y.length > 3
            ) {
                this.setDate()
            }
        })
    }

    function handleSubmit(event) {
        event.preventDefault()
        if (state.d.length > 0 && state.m.length > 0 && state.y.length > 3) {
            setDate()
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Label onClick={() => focusForm()}>Søk dato</Label>
            <div className={state.formFocused ? "visible" : ""}>
                <input
                    type="number"
                    name="d"
                    id="day"
                    placeholder="DD"
                    pattern="[0-9]*"
                    value={state.d}
                    onChange={handleChange}
                />
                <span>.</span>
                <input
                    type="number"
                    name="m"
                    id="month"
                    placeholder="MM"
                    pattern="[0-9]*"
                    value={state.m}
                    onChange={handleChange}
                />
                <span>.</span>
                <input
                    type="number"
                    name="y"
                    id="year"
                    placeholder="YYYY"
                    pattern="[0-9]*"
                    value={state.y}
                    onChange={handleChange}
                />
            </div>
        </Form>
    )
}

const Form = styled.form`
    background-color: #ffcd0e;

    @media screen and (min-width: 1024px) {
        background-color: transparent;
    }

    display: flex;
    > div {
        display: flex;
        width: 70%;
        flex-direction: row;
        justify-content: flex-end;
        @media screen and (min-width: 1024px) {
            justify-content: flex-start;
        }
        padding-right: 1em;
        transition: all 175ms ease-in-out;
        opacity: 0;
        &.visible {
            opacity: 1;
        }
        input {
            flex: 0 1 0;
            width: calc(100% / 3);
            border: none;
            margin: 0;
            padding: 20px 0 20px;
            background-color: #ffcd0e;
            font-size: 24px;
            color: #f5192b;
            text-align: right;

            @media screen and (min-width: 1024px) {
                background-color: transparent;
            }
        }
        input[name="d"],
        input[name="m"] {
            width: 30px;
        }
        input[name="y"] {
            width: 60px;
            text-align: left;
        }
        input[type="number"] {
            -moz-appearance: textfield;
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type="number"]::-webkit-input-placeholder {
            color: #a9880a;
            font-size: 15px;
            line-height: 36px;
        }
        input[type="number"]::-moz-placeholder {
            color: #a9880a;
            font-size: 15px;
            line-height: 36px;
        }
        input[type="number"]:-ms-input-placeholder {
            color: #a9880a;
            font-size: 15px;
            line-height: 36px;
        }
        input[type="number"]:-moz-placeholder {
            color: #a9880a;
            font-size: 15px;
            line-height: 36px;
        }
        span {
            padding: 20px 0 20px;
            font-size: 36px;
            line-height: 1;
        }
    }
`

const Label = styled.label`
    font-size: 24px;
    color: #f5192b;
    flex: 1 0 auto;
    padding: 24px 1em 20px;
    width: 30%;
    @media screen and (min-width: 1024px) {
        padding-left: 0;
    }
`

export default WeekSearch
