import React from "react"
import { compareDates } from "../utils/dates"
import styled from "styled-components/macro"

const ListItem = (props) => {
    const dayIndicators = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]
    function handleClick(rawDate, weekNumber) {
        console.log(rawDate, weekNumber)
    }

    function className(firstOfMonth, rawDate) {
        let cls = ""
        if (firstOfMonth) {
            cls += "first"
        }
        if (compareDates(rawDate, new Date())) {
            cls += " today"
        }
        return cls
    }

    return (
        <StyledListItem
            className={className(props.firstOfMonth, props.rawDate)}
            key={props.rawDate}
            rawDate={props.rawDate}
            weekNumber={props.weekNumber}
            isActive={compareDates(props.rawDate, new Date())}
            onClick={() => handleClick(props.rawDate, props.weekNumber)}
        >
            <span className="day">{dayIndicators[props.dayIndex]}</span>
            {props.date.toString().padStart(2, 0)}
            <br />
            <span
                className="small"
                dangerouslySetInnerHTML={{
                    __html: props.month ? props.month : "&nbsp;"
                }}
            ></span>
        </StyledListItem>
    )
}

const StyledListItem = styled.li`
    width: calc(100% / 7);
    font-size: 32px;
    text-align: center;
    position: relative;
    color: #191828;
    padding: 0.25em 0 0;
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        font-size: 32px;
    }
    &.first {
        color: rgba(245, 25, 43, 0.5);
    }
    &.today {
        color: #f5192b;
    }
    &.today::before {
        content: "";
        position: absolute;
        right: calc(50% - 2.5px);
        top: -10px;
        width: 5px;
        height: 5px;
        background-color: #f5192b;
    }
    span.small {
        font-size: 14px;
        text-transform: uppercase;
        margin-top: 4px;
    }
    span.day {
        font-size: 14px;
        text-transform: uppercase;
        display: block;
        opacity: 0.4;
        margin-bottom: 4px;
    }

    @media screen and (min-width: 1024px) {
        text-align: left;
    }
`

export default ListItem
