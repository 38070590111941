/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, its year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
export let getWeekNumber = (d) => {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))

    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))

    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))

    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)

    // Return array of year, week number and base date
    return [d.getUTCFullYear(), weekNo, d]
}

/*
 *
 * Get a YYYY-MM-DD string from a Date-object
 *
 */
export let getFullDate = (d) => {
    return (
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        d.getDate().toString().padStart(2, "0")
    )
}

/*
 *
 * Get a { y: 2021, m: 5, d: 28 } object from a Date-object
 *
 */
export let getYMDObject = (d) => {
    return {
        y: d.getFullYear(),
        m: d.getMonth() + 1,
        d: d.getDate()
    }
}

/*
 *
 * Gets the index of the day from a Date-object
 *
 */
export let getDayOfYear = (d) => {
    var now = new Date()
    var start = new Date(d.getFullYear(), 0, 0)
    var diff =
        now -
        start +
        (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000
    var oneDay = 1000 * 60 * 60 * 24
    var day = Math.floor(diff / oneDay)
    return day - 1
}

/*
 *
 * Returns an array of every single date in a given year
 * in the format ['2017-01-01', '2017-01-02', ...]
 *
 */

export let getDateListForYear = (y) => {
    if (undefined === y) {
        const d = new Date()
        y = d.getFullYear()
    }

    let yearStart = new Date(y + "/01/01")
    const yearEnd = new Date(y + "/12/31")
    var dates = [],
        currentDate = yearStart,
        addDays = function (days) {
            var date = new Date(this.valueOf())
            date.setDate(date.getDate() + days)
            return date
        },
        getDateString = function (d) {
            return (
                y +
                "-" +
                (d.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                d.getDate().toString().padStart(2, "0")
            )
        }

    while (currentDate <= yearEnd) {
        //dates.push(getDateString(currentDate));   // For a formatted string
        dates.push(currentDate) // For a date object
        currentDate = addDays.call(currentDate, 1)
    }
    return dates
}

/*
 * 'd' may be a Date-object or a week number
 */
export let getMonday = (d) => {
    if (d instanceof Date) {
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
    } else {
        const _now = new Date()
        d = getDateOfISOWeek(d, _now.getFullYear())
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
    }
    return new Date(d.setDate(diff))
}

export let getDateOfISOWeek = (w, y) => {
    var simple = new Date(y, 0, 1 + (w - 1) * 7)
    var dow = simple.getDay()
    var ISOWeekStart = simple
    if (dow <= 4) {
        ISOWeekStart.setDate(simple.getDate() - simple.getDay() + 1)
    } else {
        ISOWeekStart.setDate(simple.getDate() + 8 - simple.getDay())
    }
    return ISOWeekStart
}

export let addDays = (startDate, numberOfDays) => {
    var returnDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + numberOfDays,
        startDate.getHours(),
        startDate.getMinutes(),
        startDate.getSeconds()
    )
    return returnDate
}

/*
  Get datelist for week, either for 'd' as Date,
  or 'd' and 'y' as week number and year number
 */
export let getDateListForWeek = (d, y) => {
    if (undefined === d) {
        var d = new Date()
    }

    let dateStart = null
    if (d instanceof Date) {
        dateStart = new Date(getMonday(d))
    } else {
        dateStart = getDateOfISOWeek(d, y)
    }

    var dates = [],
        currentDate = dateStart,
        addDays = function (days) {
            var date = new Date(this.valueOf())
            date.setDate(date.getDate() + days)
            return date
        },
        getDateString = function (d) {
            return (
                y +
                "-" +
                (d.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                d.getDate().toString().padStart(2, "0")
            )
        }

    for (let i = 0; i < 7; i++) {
        //dates.push(getDateString(currentDate));   // For a formatted string
        dates.push(currentDate) // For a date object
        currentDate = addDays.call(currentDate, 1)
    }

    return dates
}

var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
]
export let getMonthName = (date) => {
    return monthNames[date.getMonth()]
}

/*
 *
 * Compares two dates. Agnostic of type (string|Date)
 * Note: If Date is passed, it will be evaluated as such,
 * regardless of its validity (e.g. Date('not-really-a-date'))
 *
 */
export let compareDates = (d1, d2) => {
    let d1c, d2c
    if (typeof d1 === "string") {
        const str = d1.substr(0, 10).replace("-", "/")
        d1c = new Date(str)
    } else {
        const str =
            d1.getFullYear() +
            "/" +
            (d1.getMonth() + 1).toString().padStart(2, "0") +
            "/" +
            d1.getDate().toString().padStart(2, "0")
        d1c = new Date(str)
    }

    if (typeof d2 === "string") {
        const str = d2.substr(0, 10).replace("-", "/")
        d2c = new Date(str)
    } else {
        const str =
            d2.getFullYear() +
            "/" +
            (d2.getMonth() + 1).toString().padStart(2, "0") +
            "/" +
            d2.getDate().toString().padStart(2, "0")
        d2c = new Date(str)
    }
    //console.log(d1c.getTime() + ' vs. ' + d2c.getTime());
    return d1c.getTime() === d2c.getTime()
}
