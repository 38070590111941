import React, { useState } from "react"
import {
    getDateListForYear,
    getWeekNumber,
    getDayOfYear,
    getDateListForWeek,
    getMonthName
} from "../utils/dates"
import ListItem from "./YearListItem"
import styled from "styled-components/macro"
// import { scroller, scrollSpy } from "react-scroll"
import AnimatedNumber from "react-animated-number"

/*
var StaggerIn = React.createClass({
  render: function() {
    var childCount = React.Children.count(props.children);
    var children = React.Children.map(props.children, function(child, idx) {
      var inDelay = props.delay * idx;
      var outDelay = props.delay * (childCount - idx - 1);
      return (
        <StaggeringChild key={child.key}
                         animateInDelay={inDelay}
                         animateOutDelay={outDelay}>
          {child}
        </StaggeringChild>
      );
    }.bind(this));

    return (
      <React.addons.TransitionGroup>
        {children}
      </React.addons.TransitionGroup>
    );
  }
});


var StaggeringChild = React.createClass({
  getDefaultProps: function() {
    return {
      tag: "div"
    };
  },

  componentWillAppear: function(callback) {
    _animateIn(callback);
  },

  componentWillEnter: function(callback) {
    _animateIn(callback);
  },

  componentWillLeave: function(callback) {
    _animateOut(callback);
  },

  _animateIn(callback) {
    var el = React.findDOMNode(this);
    TweenLite.set(el, {opacity: 0});
    setTimeout(function() {
      console.log("timed in");
      TweenLite.to(el, 1, {opacity: 1}).play().eventCallback("onComplete", callback);
    }, props.animateInDelay);
  },

  _animateOut(callback) {
    var el = React.findDOMNode(this);
    setTimeout(function() {
      TweenLite.to(el, 1, {opacity: 0}).play().eventCallback("onComplete", callback);
    }, props.animateOutDelay);
  },

  render: function() {
    var Comp = props.tag;
    var { tag, animateInDelay, animateOutDelay } = props;

    return <Comp {...props}>{props.children}</Comp>;
  }
});
*/

const WeekSlide = (props) => {
    const [state, setState] = useState({
        activeEl: getDayOfYear(new Date())
    })
    const [showYear] = useState(false) // True if the entire year should display in the scroller

    function getMonthFromString(fullDate) {
        return fullDate.split("-")[1]
    }

    function getDateFromString(fullDate) {
        return fullDate.split("-")[2]
    }

    function getMonth(date) {
        return date.getMonth() + 1
    }

    function getDate(date) {
        return date.getDate()
    }

    /*
	componentDidMount() {
		//scrollSpy.update();
		//scroller.scrollTo(state.activeEl, false, 0, -65);
		if(showYear){
			scrollToDayIndex(state.activeEl);
		}
	}
	
	componentWillUnmount() {
		//Events.scrollEvent.remove('begin');
		//Events.scrollEvent.remove('end');
	}

	componentWillMount(){

	}
	*/

    /*
    function scrollToDayIndex(i) {
        setState({ activeEl: i })
        const targetListItem = ReactDOM.findDOMNode(r[i])
        const targetScrollItem = targetListItem.parentElement

        var targetListItemRect = targetListItem.getBoundingClientRect()
        console.log(targetScrollItem.clientWidth)

        targetScrollItem.scrollLeft =
            targetListItemRect.left - (targetScrollItem.clientWidth - 32) / 2

        console.log(targetListItem.scrollLeft)
	}
	*/

    let dateList = null
    if (showYear) {
        dateList = getDateListForYear()
    } else {
        dateList = getDateListForWeek(props.weekNumber, props.yearNumber)
    }

    //console.log('dateList', dateList);

    var elements = []
    var monthRunner = getMonth(dateList[0])
    var monthDisplayer = null
    const today = new Date()

    for (var ix = 0; ix < dateList.length; ix++) {
        let currentMonth = getMonth(dateList[ix])
        let printMonth = false
        if (showYear) {
            printMonth = currentMonth !== monthRunner
        } else {
            printMonth = !monthDisplayer || currentMonth !== monthRunner
        }

        const li = (
            <ListItem
                key={ix}
                //ref={ix.toString()}
                dayIndex={ix}
                firstOfMonth={currentMonth !== monthRunner ? true : false}
                weekNumber={getWeekNumber(dateList[ix])}
                rawDate={dateList[ix]}
                month={printMonth ? getMonthName(dateList[ix]) : null}
                date={getDate(dateList[ix])}
            />
        )

        elements.push(li)

        monthDisplayer = monthRunner

        if (currentMonth !== monthRunner) {
            monthRunner = currentMonth
        }
    }

    return (
        <Slider>
            {/*state.activeEl*/}
            <List>{elements}</List>
            {/*<button onClick={scrollToDayIndex.bind(this, getDayOfYear(new Date()))}>Today</button>*/}
        </Slider>
    )
}

const Slider = styled.div`
    overflow-x: scroll;
    background-color: #f5f5f8;
    position: relative;

    @media screen and (min-width: 768px) {
        background-color: transparent;
    }
`

const WeekNav = styled.a`
    color: #222;
    background-color: rgba(255, 255, 255, 0.6);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: calc(50% - 50px);
    padding: 0 25px;
    font-size: 64px;
    z-index: 1;
    &:last-of-type {
        right: 0;
        left: auto;
        top: 0;
        text-align: right;
    }
`

const List = styled.ul`
    font-size: 16px;
    list-style-type: none;
    white-space: nowrap;
    overflow-x: auto;
    padding: 10px 0 10px;
    margin-bottom: 0;
    display: flex;
`

export default WeekSlide
