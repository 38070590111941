import React from "react"
import styled from "styled-components/macro"
import AnimatedNumber from "react-animated-number"

const WeekDisplay = (props) => {
    function updateWeek(nav) {
        props.onWeekNumberChange(nav)
    }

    return (
        <Title>
            <Links>
                <BackLink
                    visible={props.shouldShowBackButton}
                    onClick={() => updateWeek(0)}
                >
                    ◄ Today
                </BackLink>
            </Links>
            <Week>
                <WeekNav onClick={() => updateWeek(-1)}> ◄ </WeekNav>
                <WeekInfo>
                    <PreTitle>Uke</PreTitle>
                    <AnimatedNumber
                        value={props.weekNumber}
                        duration={400}
                        style={{
                            transition: "opacity 125ms ease-out",
                            opacity: 0,
                            fontSize: 128,
                            color: "#2e0e9f"
                        }}
                        stepPrecision={0}
                        frameStyle={(perc) => {
                            return !perc || perc === 0 || perc === Infinity
                                ? {}
                                : { opacity: parseFloat(perc / 100) }
                        }}
                    />
                    <YearIndicator>{props.yearNumber}</YearIndicator>
                </WeekInfo>
                <WeekNav onClick={() => updateWeek(1)}> ► </WeekNav>
            </Week>
        </Title>
    )
}

const Title = styled.div`
    text-align: center;
    margin: 0.25em 0 0 0;
    font-weight: 400;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Links = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
`

const BackLink = styled.a`
    font-size: 14px;
    padding: 14px 24px;
    transition: opacity 225ms ease-in-out;
    opacity: ${(props) => (props.visible ? 1 : 0)};
`

const Week = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
`

const WeekInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const PreTitle = styled.span`
    font-size: 32px;
    text-align: center;
    color: #2e0e9f;
    margin-top: 1em;
    display: block;
`

const YearIndicator = styled.span`
    font-size: 16px;
    text-align: center;
    color: rgba(46, 14, 159, 0.4);
    margin-top: 0;
    margin-bottom: 1em;
    display: block;
`

const WeekNav = styled.a`
    @keyframes weeknav {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    font-family: arial;
    color: rgba(0, 0, 0, 0.2);
    height: 100%;
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 0;*/
    /*width: 25%;*/
    /*padding: 0 25px;*/
    font-size: 64px;
    z-index: 1;
    text-align: left;
    line-height: 192px;
    opacity: 0.5;
    transition: opacity 125ms ease-in;

    &:hover {
        cursor: pointer;
    }
    &:hover,
    &:active {
        /*animation: weeknav 500ms reverse;*/
    }

    &:last-of-type {
        right: 0;
        left: auto;
        top: 0;
        text-align: right;
    }
`

export default WeekDisplay
